import Heading from 'DesignSystem/Typography/Headings/Heading';
import { styled } from 'Theme/stitches.config';
import FullSearchResultModel from 'Models/Search/FullSearchResultModel.interface';
import ProductCard from 'Commerce/Molecules/ProductCard/ProductCard';
import MagazineCard from 'Commerce/Molecules/MagazineCard/MagazineCard';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import LoadMoreContainer from 'DesignComponents/Molecules/LoadMoreContainer/LoadMoreContainer';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';

type SearchResultType = {
  result: FullSearchResultModel;
  isLoading: boolean;
  loadMoreObj?: {
    productHits: number;
    loadMore: string;
    disableButton: boolean;
    onClick?: ((x?: (() => void) | undefined) => void) | undefined;
    loadingMore: boolean;
  };
  hideMagazines?: boolean;
  hideProducts?: boolean;
  hidePages?: boolean;
  hideSubscriptionOffers?: boolean;
  hideMagazinesHeader?: boolean;
  hideProductsHeader?: boolean;
  hidePagesHeader?: boolean;
  hideSubscriptionOffersHeader?: boolean;
};

function SearchResult({
  result,
  loadMoreObj,
  isLoading,
  hideMagazines = false,
  hideProducts = false,
  hidePages = false,
  hideSubscriptionOffers = false,
  hideMagazinesHeader = false,
  hideProductsHeader = false,
  hidePagesHeader = false,
  hideSubscriptionOffersHeader = false,
}: SearchResultType) {
  const {
    searchLabels: {
      products: productsText,
      pages: pagesText,
      magazines: magazinesText,
      subscriptionOffers: subscriptionOffersText,
    },
  } = useTranslationData();

  return (
    <>
      {
        // Magazines
        result.magazines &&
          result.magazines.availableItems > 0 &&
          !hideMagazines && (
            <>
              <SearchColumn>
                {!hideMagazinesHeader && (
                  <ColumnHeader>
                    <Heading tag="h2" size="l" id="magazinesSearchHeader">
                      {magazinesText}
                    </Heading>
                  </ColumnHeader>
                )}

                <ColumnList
                  itemsInRow={4}
                  aria-labelledby="magazinesSearchHeader"
                >
                  {result.magazines.items.map((item, index) => {
                    return (
                      <ColumnListItem key={index}>
                        <MagazineCard
                          item={{
                            code: item.id,
                            cover: item.image,
                            fromNoOfIssuesText: item.fromNoOfIssuesText,
                            link: {
                              href: item.url,
                              id: 'string',
                              target: '_blank',
                              text: item.introText,
                              title: item.name,
                            },
                            name: item.name,
                            priceFrom: item.nowPrice,
                            wasPrice: item.wasPrice,
                            youSaveSticker: item.youSaveSticker,
                          }}
                          showHorizontalScrollOnMobile={false}
                          showPriceInfo={true}
                        ></MagazineCard>
                      </ColumnListItem>
                    );
                  })}
                </ColumnList>
              </SearchColumn>
            </>
          )
      }
      {
        // Article Products
        result?.articleProducts &&
          result.articleProducts.availableItems > 0 &&
          !hideProducts && (
            <>
              <SearchColumn>
                {!hideProductsHeader && (
                  <ColumnHeader>
                    <Heading tag="h2" size="l" id="productsSearchHeader">
                      {productsText}
                    </Heading>
                  </ColumnHeader>
                )}

                <ColumnList
                  itemsInRow={3}
                  aria-labelledby={
                    !hideProductsHeader ? 'productsSearchHeader' : undefined
                  }
                >
                  {result.articleProducts.items.map((item, index) => {
                    return (
                      <ColumnListItem key={index}>
                        <ProductCard item={item} />
                      </ColumnListItem>
                    );
                  })}
                </ColumnList>
                {loadMoreObj &&
                  loadMoreObj.productHits !== 0 &&
                  !isLoading &&
                  !loadMoreObj.disableButton && (
                    <LoadMoreContainer
                      loadMore={loadMoreObj.loadMore}
                      disabled={loadMoreObj.disableButton}
                      onClick={loadMoreObj.onClick}
                      isLoading={loadMoreObj.loadingMore}
                    />
                  )}
              </SearchColumn>
            </>
          )
      }
      {
        // Subscription offers
        result?.subscriptionOffers &&
          result.subscriptionOffers.availableItems > 0 &&
          !hideSubscriptionOffers && (
            <>
              <SearchColumn>
                {!hideSubscriptionOffersHeader && (
                  <ColumnHeader>
                    <Heading
                      tag="h2"
                      size="l"
                      id="subscriptionOffersSearchHeader"
                    >
                      {subscriptionOffersText}
                    </Heading>
                  </ColumnHeader>
                )}
                <ColumnList
                  itemsInRow={3}
                  aria-labelledby="subscriptionOffersSearchHeader"
                >
                  {result.subscriptionOffers.items.map((item, index) => {
                    return (
                      <ColumnListItem key={index}>
                        <ProductCard item={item} />
                      </ColumnListItem>
                    );
                  })}
                </ColumnList>
                {loadMoreObj &&
                  loadMoreObj.productHits !== 0 &&
                  !isLoading &&
                  !loadMoreObj.disableButton && (
                    <LoadMoreContainer
                      loadMore={loadMoreObj.loadMore}
                      disabled={loadMoreObj.disableButton}
                      onClick={loadMoreObj.onClick}
                      isLoading={loadMoreObj.loadingMore}
                    />
                  )}
              </SearchColumn>
            </>
          )
      }
      {
        // CMS Pages
        result?.pages && result.pages.availableItems > 0 && !hidePages && (
          <>
            <SearchColumn>
              {!hidePagesHeader && (
                <ColumnHeader>
                  <Heading tag="h2" size="l" id="pagesSearchHeader">
                    {pagesText}
                  </Heading>
                </ColumnHeader>
              )}

              <ColumnList itemsInRow={2} aria-labelledby="pagesSearchHeader">
                {result.pages.items.map((item, index) => {
                  return (
                    <ColumnListItem
                      key={index}
                      css={{
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: '$staticBorderDefault',
                      }}
                    >
                      <ContentSearchResultItem>
                        <InteractableContentLink href={item.url}>
                          <span>{item.name}</span>
                        </InteractableContentLink>
                        <BodyText>{item.introText}</BodyText>
                      </ContentSearchResultItem>
                    </ColumnListItem>
                  );
                })}
              </ColumnList>
            </SearchColumn>
          </>
        )
      }
    </>
  );
}

export default SearchResult;

const SearchColumn = styled('div', {
  mb: 8,
  '@bpMin721': {
    mb: 18,
  },
});

const ColumnHeader = styled('div', {
  borderStyle: 'none',
  mb: 4,
});

const ColumnList = styled('ul', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)', // 2 in row mobile
  gap: '24px 12px',
  variants: {
    itemsInRow: {
      2: {
        gridTemplateColumns: 'repeat(1, 1fr)',
        gap: '16px 16px',
        '@bpMin721': {
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: '32px 32px',
        },
        '@bpMin1150': { gridTemplateColumns: 'repeat(2, 1fr)' },
      },
      3: {
        '@bpMin721': {
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr));',
          gap: '48px 32px',
        },
      },
      4: {
        '@bpMin721': {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '16px 16px',
        },
        '@bpMin860': {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '@bpMin1150': { gridTemplateColumns: 'repeat(4, 1fr)' },
      },
    },
  },
});

const ColumnListItem = styled('li', {});

const ContentSearchResultItem = styled('div', {
  p: 6,
  '& a span': {
    display: 'block',
    fontFamily: 'fontSemiBold',
    fontSize: '$fontSize200',
    lineHeight: '$lh24',
    mb: 2,
    color: '$onSurface',
  },
});
