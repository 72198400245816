import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';

type SaveTagType = {
  children?: React.ReactNode;
  css?: CSS;
};

function DiscountTag({ children, css }: SaveTagType) {
  return <Save css={css}>{children}</Save>;
}

const Save = styled('div', {
  backgroundColor: '$white',
  color: '$onSurface',
  fontSize: '$fontSize50',
  fontFamily: 'fontBold',
  lineHeight: '$lh16',
  position: 'absolute',
  textTransform: 'uppercase',
  t: 4,
  r: 4,
  px: '$s50',
  py: '$s25',
  zIndex: 2,
});

export default DiscountTag;
