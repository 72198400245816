import { styled } from 'Theme/stitches.config';
import DiscountTag from './DiscountTag';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import ImageModel from 'Models/Assets/ImageModel.interface';
import {
  ImageScalingTypes,
  getResizedImageFromEgmontCDN,
} from 'Shared/Common/ResizeImageEgmontCDN';
import { CSS } from '@stitches/react';

type ProductCardImageType = {
  image: ImageModel;
  premiumImage?: ImageModel;
  large?: boolean;
  youSaveSticker?: string;
  imageScalingType?: ImageScalingTypes;
  inCart?: boolean;
  discountTagCss?: CSS;
};

function ProductCardImage({
  image,
  premiumImage,
  youSaveSticker,
  imageScalingType = ImageScalingTypes.MAGAZINE_CARD,
  inCart = false,
  discountTagCss,
}: ProductCardImageType) {
  const { missingImage, highlightPremiumImages } =
    useAppSettingsData().imageSettings;

  const mainImage = highlightPremiumImages ? premiumImage : image;
  const secondaryImage = highlightPremiumImages ? image : premiumImage;
  const mainImageSrc = mainImage
    ? getResizedImageFromEgmontCDN(mainImage.src, imageScalingType)
    : secondaryImage
    ? getResizedImageFromEgmontCDN(secondaryImage.src, imageScalingType)
    : missingImage.src;

  const coverBackground = mainImage != undefined && highlightPremiumImages;

  return (
    <ProductImageWrapper coverBackground={coverBackground} inCart={inCart}>
      {youSaveSticker && (
        <DiscountTag css={discountTagCss}>{youSaveSticker}</DiscountTag>
      )}
      <ProductImage
        src={mainImageSrc}
        alt={mainImage ? mainImage.alt : ''}
        coverBackground={coverBackground}
      />
      {secondaryImage && mainImage && (
        <PremiumImage
          src={getResizedImageFromEgmontCDN(
            secondaryImage.src,
            ImageScalingTypes.PREMIUM_IMAGE
          )}
          alt={secondaryImage.alt || ''}
        />
      )}
    </ProductImageWrapper>
  );
}
const ProductImageWrapper = styled('div', {
  backgroundColor: '$productCardBackground',
  position: 'relative',
  py: '$s100',
  px: '$s100',
  aspectRatio: '1/1',

  variants: {
    coverBackground: {
      true: {
        p: 0,
      },
    },
    inCart: {
      true: {
        maxWidth: '120px',
        maxHeight: '120px',
      },
    },
  },
});
const ProductImage = styled('img', {
  wh: '100%',
  objectFit: 'contain',
  mx: 'auto',
  filter: 'drop-shadow(0 0 0.1rem rgb(0, 0, 0, 0.5))',
  variants: {
    coverBackground: {
      true: {
        objectFit: 'cover',
      },
    },
  },
});
const PremiumImage = styled('img', {
  position: 'absolute',
  b: 3,
  r: 4,
  maxHeight: '50%',
  maxWidth: '50%',
});
export default ProductCardImage;
