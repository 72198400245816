import loadable from '@loadable/component';

export type LoadableButton = Record<string, React.ElementType>;

const Buttons: LoadableButton = {
  IconButton: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'DesignComponents/Atoms/Buttons/IconButton'
      )
  ),
  PrimaryButton: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'DesignComponents/Atoms/Buttons/PrimaryButton'
      )
  ),
  SecondaryButton: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'DesignComponents/Atoms/Buttons/SecondaryButton'
      )
  ),
  TertiaryButton: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'DesignComponents/Atoms/Buttons/TertiaryButton'
      )
  ),
};

export default Buttons;
