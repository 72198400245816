import { SrOnlyStyle } from 'DesignSystem/Accessibility/Utils';
import PriceModel from 'Models/Price/PriceModel.interface';
import { styled } from 'Theme/stitches.config';

type PriceType = {
  currentPrice: PriceModel;
  wasPrice?: string;
};

function PriceInfo({ currentPrice, wasPrice }: PriceType) {
  return (
    <Price>
      {currentPrice && (
        <CurrentPrice itemProp="price">
          {currentPrice.priceAsString}{' '}
          <Currency>{currentPrice.currencySymbol}</Currency>
        </CurrentPrice>
      )}
      {wasPrice && (
        <>
          <PriceDesc>Original price</PriceDesc>
          <PrevPrice>{wasPrice}</PrevPrice>
        </>
      )}
    </Price>
  );
}

const Price = styled('div', {
  display: 'flex',
  gap: '$s25',
  alignItems: 'baseline',
});

const CurrentPrice = styled('div', {
  fontSize: '$fontSize700',
  fontFamily: 'fontBold',
  color: '$onSurface',
  pr: '$s25',
  whiteSpace: 'nowrap',
  '@bpMax480': {
    fontSize: '$fontSize500',
  },
});

const Currency = styled('span', {
  fontSize: '$fontSize100',
});

const PriceDesc = styled('span', {
  ...SrOnlyStyle,
});

const PrevPrice = styled('s', {
  fontSize: '$fontSize100',
  display: 'flex',
  color: '$onWasPrice',

  whiteSpace: 'nowrap',
});
export default PriceInfo;
