import { styled } from 'Theme/stitches.config';
import { useAppSettingsData } from '../../../../Shared/Providers/AppSettingsProvider';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import Button from 'Shared/Components/Buttons/Button';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';

function SearchNoResult() {
  const isMobile = useMedia(mediaQueryTypes.bpMax720);
  const {
    searchSettings: { noResultText, noResultBullets },
  } = useAppSettingsData();
  const {
    searchLabels: { newSearch: newSearchText },
  } = useTranslationData();
  const { focusQuickSearch } = useUiState();

  const newSearchAction = () => {
    focusQuickSearch(true);
  };

  return (
    <MainContainer>
      <Heading tag="h3" size="m" weights="semiBold" css={{ mb: 2 }}>
        {noResultText}
      </Heading>
      {noResultBullets && (
        <List>
          {noResultBullets.map((item: string) => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </List>
      )}
      {newSearchText && (
        <NoResultButtonWrapper>
          <Button<typeof PrimaryButton>
            props={{
              text: newSearchText,
              color: 'Regular',
              size: isMobile ? 's' : 'm',
            }}
            element="PrimaryButton"
            onClick={newSearchAction}
          />
        </NoResultButtonWrapper>
      )}
    </MainContainer>
  );
}

export default SearchNoResult;

const MainContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  mx: 'auto',
  mt: 0,
  '@bpMin721': {
    p: 6,
    w: 640,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '$staticBorderDefault',
  },
});

const List = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'unset',
  pl: 6,
  mb: 8,
});

const ListItem = styled('li', {
  lineHeight: '$lh24',
  fontSize: '$fontSize100',
  fontFamily: 'fontRegular',
  '@bpMin721': {
    lineHeight: '$lh28',
    fontSize: '$fontSize200',
  },
});

const NoResultButtonWrapper = styled('div', {
  mx: 'auto',
});
