import { styled } from 'Theme/stitches.config';
import Label from 'DesignSystem/Typography/Label/Label';
import { useEffect } from 'react';

type PropType = {
  isChecked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  text?: string;
  value?: string | number;
  isDisabled?: boolean;
  error?: boolean;
  noMargin?: true;
  groupName?: string;
  focused?: boolean;
};

function RadioButton({
  isChecked,
  onChange,
  value,
  text,
  isDisabled,
  error,
  noMargin,
  groupName,
  focused,
}: PropType) {
  const id = groupName + '_' + value?.toString();

  useEffect(() => {
    if (focused && value !== null && value !== undefined) {
      document.getElementById(id)?.focus();
    }
  }, [focused]);

  return (
    <Label
      interactive
      disabled={isDisabled}
      error={error}
      focusWithin={true}
      css={{ fontSize: '$fontSize75' }}
    >
      <Input
        disabled={isDisabled}
        isDisabled={isDisabled}
        error={error}
        type="radio"
        id={id}
        value={value}
        checked={isChecked}
        onChange={onChange}
        noMargin={noMargin}
        name={groupName}
        focusWithin={true}
      />
      {text}
    </Label>
  );
}

export default RadioButton;

const Input = styled('input', {
  display: 'flex',
  mr: '$s50',
  alignItems: 'center',
  justifyContent: 'center',
  appearance: 'none',
  cursor: 'pointer',
  backgroundColor: '$surface',
  wh: 24,
  borderRadius: 12,
  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: '$interactiveBorder',
  position: 'relative',

  '&:focus-visible': {
    outlineOffset: '2px',
    outline: 'solid 2px $focusBorder',
  },

  variants: {
    checked: {
      true: {
        borderColor: '$interactiveBorderActive',
        borderWidth: 2,
        '&:focus': {},
        '&:before': {
          position: 'absolute',
          content: '""',
          wh: 14,
          backgroundColor: '$interactiveBorderActive',
          borderRadius: '50%',
        },
      },
    },
    error: {
      true: {
        borderColor: '$semanticError',
        borderWidth: 2,
      },
    },
    isDisabled: {
      true: {
        cursor: 'default',
        borderColor: '$interactiveDisabled_1',
      },
    },
    noMargin: {
      true: {
        mr: 0,
      },
    },
    focusWithin: {
      true: {
        '&:focus-visible': {
          boxShadow: 'none !Important',
          outline: 'none',
        },
      },
    },
  },
  compoundVariants: [
    {
      checked: true,
      error: true,
      css: {
        '&:before': {
          backgroundColor: '$semanticError',
        },
      },
    },
    {
      checked: true,
      isDisabled: true,
      css: {
        '&:before': {
          backgroundColor: '$interactiveDisabled_1',
        },
      },
    },
  ],
});
