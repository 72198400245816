import loadable from '@loadable/component';
import { GetComponentProps } from 'Shared/Types/types';
import MiniCartContent from '../../../Commerce/Cart/MiniCart/MiniCartContent';
import SearchFilterFacetsModalContainer from '../../../Cms/Pages/SearchPage/SearchFilterFacets/SearchFilterFacetsModalContainer';
import CountryPickerModalContainer from '../../../Commerce/CountryPicker/CountryPickerModalContainer';
import TermsAndConditionsModalContainer from 'Commerce/Pages/Checkout/Components/TermsAndConditions/TermsAndConditionsModalContainer';
type LoadableModalViews<T extends string> = Record<T, React.ElementType>;
export type ModalViewTypes =
  | typeof MiniCartContent
  | typeof SearchFilterFacetsModalContainer
  | typeof CountryPickerModalContainer
  | typeof TermsAndConditionsModalContainer;

export const ModalContentViews = {
  MiniCart: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Commerce/Cart/MiniCart/MiniCartContent'
      )
  ),
  Filter: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Cms/Pages/SearchPage/SearchFilterFacets/SearchFilterFacetsModalContainer'
      )
  ),
  CountryPicker: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Commerce/CountryPicker/CountryPickerModalContainer'
      )
  ),
  TermsAndConditions: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../../../Commerce/Pages/Checkout/Components/TermsAndConditions/TermsAndConditionsModalContainer'
      )
  ),
};

const loadableViews: LoadableModalViews<keyof typeof ModalContentViews> =
  ModalContentViews;

export const mapModalView = <T extends ModalViewTypes>({
  props,
  type,
}: {
  type: keyof typeof loadableViews;
  props: GetComponentProps<T>;
}) => {
  const ModalView = loadableViews[type];
  return <ModalView {...(props as object)} />;
};
