import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Filter({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M4.84615 10.7692C5.86576 10.7692 6.69231 9.94266 6.69231 8.92306C6.69231 7.90346 5.86576 7.0769 4.84615 7.0769C3.82655 7.0769 3 7.90346 3 8.92306C3 9.94266 3.82655 10.7692 4.84615 10.7692Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M4.84766 10.7693V20.0001"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M4.84766 4V7.07692" stroke={color} strokeRound />
      <BaseIconPathStyle
        d="M19.1528 10.7692C20.1724 10.7692 20.9989 9.94266 20.9989 8.92306C20.9989 7.90346 20.1724 7.0769 19.1528 7.0769C18.1332 7.0769 17.3066 7.90346 17.3066 8.92306C17.3066 9.94266 18.1332 10.7692 19.1528 10.7692Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M19.1543 7.07692V4" stroke={color} strokeRound />
      <BaseIconPathStyle
        d="M19.1543 20.0001V10.7693"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle
        d="M12.0005 13.8461C13.0201 13.8461 13.8466 13.0196 13.8466 12C13.8466 10.9804 13.0201 10.1538 12.0005 10.1538C10.9808 10.1538 10.1543 10.9804 10.1543 12C10.1543 13.0196 10.9808 13.8461 12.0005 13.8461Z"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M12 4V10.1538" stroke={color} strokeRound />
      <BaseIconPathStyle d="M12 13.8461V19.9999" stroke={color} strokeRound />
    </BaseSvgStyle>
  );
}

export default Filter;
