import { styled } from 'Theme/stitches.config';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { CSS } from '@stitches/react';
import MagazineCardModel from 'Models/MagazineCard/MagazineCardModel.interface';
import DiscountTag from '../ProductCard/DiscountTag';
import PriceInfo from '../ProductCard/PriceInfo';
import {
  ImageScalingTypes,
  getResizedImageFromEgmontCDN,
} from 'Shared/Common/ResizeImageEgmontCDN';
import { FocusVisibleStyle } from 'DesignSystem/Accessibility/Utils';

type MagazineCardType = {
  item: MagazineCardModel;
  inEditMode?: boolean;
  css?: CSS;
  width?: number;
  showHorizontalScrollOnMobile?: boolean;
  showPriceInfo?: boolean;
};

function MagazineCard({
  item,
  width,
  css,
  showPriceInfo = false,
  showHorizontalScrollOnMobile,
}: MagazineCardType) {
  const {
    cover,
    link,
    name,
    fromNoOfIssuesText,
    priceFrom,
    wasPrice,
    youSaveSticker,
  } = item;
  const { missingImage } = useAppSettingsData().imageSettings;
  const kexNavigate = useKexNavigate();

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (link.href) {
      if (event.target instanceof HTMLAnchorElement) {
        // Prevent the default click event for the anchor tag
        event.preventDefault();
      }
      kexNavigate(link.href);
    }
  };

  return (
    <Card
      css={width ? { ...css, minW: width } : css}
      showHorizontalScrollOnMobile={
        showHorizontalScrollOnMobile !== undefined
          ? showHorizontalScrollOnMobile
          : false
      }
      onClick={handleCardClick}
      itemScope
    >
      <ProductImageWrapper>
        {showPriceInfo && youSaveSticker && (
          <DiscountTag>{youSaveSticker}</DiscountTag>
        )}
        <ProductImage
          src={
            cover && cover.src
              ? getResizedImageFromEgmontCDN(
                  cover.src,
                  ImageScalingTypes.MAGAZINE_CARD
                )
              : missingImage.src
          }
          alt={cover ? cover.alt : ''}
        />
      </ProductImageWrapper>
      <InfoWrapper>
        <TitleAndIssues>
          <CardLink href={link.href} itemProp="name">
            {name}
          </CardLink>
          {fromNoOfIssuesText && showPriceInfo && (
            <IssuesInfo>{fromNoOfIssuesText}</IssuesInfo>
          )}
        </TitleAndIssues>
        {showPriceInfo && priceFrom && !priceFrom.isZero && (
          <PriceInfo
            currentPrice={priceFrom}
            wasPrice={
              wasPrice && !wasPrice.isZero
                ? wasPrice.priceWithSymbol
                : undefined
            }
          />
        )}
      </InfoWrapper>
    </Card>
  );
}

const CardLink = styled('a', {
  display: 'block',
  fontSize: '$fontSize200',
  lineHeight: '$lh24',
  fontFamily: 'fontSemiBold',
  '@bpMax480': {
    fontSize: '$fontSize75',
  },
  '&:focus-visible': {
    outline: 'none !Important',
  },
});

const Card = styled('article', {
  color: '$onSurface',
  cursor: 'pointer',
  borderStyle: 'solid',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.2s ease-in-out',
  w: 'auto',
  mb: '$s100',

  '&:hover': {
    [`& ${CardLink}`]: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationThickness: ' 2px',
    },
  },
  '&:focus-within': {
    ...FocusVisibleStyle,
  },
  variants: {
    showHorizontalScrollOnMobile: {
      true: {
        '@bpMax720': {
          w: 150,
        },
        '@bpMax480': {
          w: 140,
        },
        '@bpMax375': {
          w: 130,
        },
      },
    },
  },
});

const ProductImageWrapper = styled('div', {
  backgroundColor: '$decorationBackground',
  position: 'relative',
  px: '$s150',
  py: '$s100',
  '@bpMin961': {
    px: '$s150',
    py: '$s100',
  },
  '@bpMax375': {
    px: '$s100',
    py: '$s75',
  },
});

const ProductImage = styled('img', {
  left: 0,
  height: '100%',
  width: '100%',
  objectFit: 'contain',
  opacity: 1,
  filter: 'drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 0.1rem)',
  '&:hover': {
    cursor: 'pointer',
  },
});

const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  pt: 2,
});

const IssuesInfo = styled('span', {
  display: 'block',
  fontSize: '$fontSize75',
  mt: '$s25',
  mb: '$s100',
  '@bpMax480': {
    fontSize: '$fontSize50',
  },
});

const TitleAndIssues = styled('div', {
  pb: 1,
});

export default MagazineCard;
