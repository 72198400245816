import Button from 'Shared/Components/Buttons/Button';
import { styled } from 'Theme/stitches.config';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';

export type LoadMorePropType = {
  loadMore: string;
  disabled?: boolean;
  onClick?: (x?: () => void) => void;
  isLoading: boolean;
};
const LoadMoreContainer = ({
  loadMore,
  disabled = false,
  onClick,
  isLoading,
}: LoadMorePropType) => {
  return (
    <LoadContainer>
      <ButtonWrapper>
        <Button<typeof PrimaryButton>
          props={{
            text: loadMore,
            color: 'Regular',
            size: 'm',
            disabled: disabled,
          }}
          element="PrimaryButton"
          onClick={() => {
            onClick && onClick();
          }}
          isLoading={isLoading}
        />
      </ButtonWrapper>
    </LoadContainer>
  );
};

export default LoadMoreContainer;

const LoadContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mx: 'auto',
});

const ButtonWrapper = styled('div', {
  pt: '$s150',
});
