import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import { ButtonTypes } from 'DesignComponents/Atoms/Buttons/BaseButtonType';
import { useEffect, useState } from 'react';
import { styled } from 'Theme/stitches.config';
import { GetComponentProps } from 'Shared/Types/types';
import Buttons from './LoadableButtons';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import { CSS } from '@stitches/react';

type buttonProps<T extends ButtonTypes> = {
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
  element: keyof typeof Buttons;
  props: Omit<GetComponentProps<T>, 'pressed'>;
  css?: CSS;
  type?: 'button' | 'reset' | 'submit';
  id?: string;
};
/**
 * @param {keyof typeof Buttons} element The button component to render
 * @param {Omit<GetComponentProps<T>, 'pressed'>} props depends on <typed ****>
 */
const Button = <T extends ButtonTypes>({
  element,
  isLoading = false,
  props,
  css,
  id,
  type = 'button',
  onClick,
}: buttonProps<T>) => {
  const [loading, setLoading] = useState<boolean>(isLoading);
  const [pressed, setPressed] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  const Element = Buttons[element];

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (type !== 'submit') {
      e.currentTarget.blur();
      e.preventDefault();
      onClick && onClick();
    }
  };

  const handleMouse = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    pressed: boolean
  ) => {
    if (type !== 'submit') {
      e.currentTarget.blur();
      e.preventDefault();
      setPressed(() => pressed);
    }
  };

  useEffect(() => {
    setLoading(isLoading);
    setPressed(false);
  }, [isLoading]);

  return (
    <StyledButton
      onMouseDown={(e) => handleMouse(e, true)}
      onMouseUp={(e) => handleMouse(e, false)}
      onMouseLeave={(e) => handleMouse(e, false)}
      onFocus={() => setFocus(() => true)}
      onBlur={() => setFocus(() => false)}
      onClick={(e) => handleOnClick(e)}
      hug={props.hug}
      disabled={props.disabled}
      css={css}
      id={id}
    >
      {Element && (
        <Element {...props} pressed={pressed} focus={focus}>
          {loading && (
            <StyledLoadingCircle>
              <AnimatedIcon animation={'spinAnimation'}>
                <LoadingCircle />
              </AnimatedIcon>
            </StyledLoadingCircle>
          )}
        </Element>
      )}
    </StyledButton>
  );
};

const StyledButton = styled('button', {
  position: 'relative',

  '&:disabled': {
    cursor: 'not-allowed',
  },
  variants: {
    hug: {
      height: {
        h: '100%',
      },
      width: {
        w: '100%',
      },
    },
  },
});

const StyledLoadingCircle = styled('div', {
  position: 'absolute',
  wh: '100%',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'inherit',
});

export default Button;
